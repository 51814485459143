import React, { memo as memoDefault } from "react";
import { Hidden as UnstyledHidden } from "utils/material-ui-core";

// `any` / `any` types are intentional and desired types, matching
// that of our react-fast-compare library
type Comparator = (a: any, b: any) => boolean;

export const Hidden = props => (
  <>
    {!process.env.SERVER ? (
      <UnstyledHidden {...props}>{props.children}</UnstyledHidden>
    ) : (
      <UnstyledHidden implementation="css" {...props}>
        {props.children}
      </UnstyledHidden>
    )}
  </>
);
export const memo = (it, extra?: Comparator) =>
  process.env.SERVER ? it : memoDefault(it, extra);
export const processEnvServer = !!process.env.SERVER;
export const ProcessEnvServerRender = ({ onServer = null, ...props }) => (
  <>{!!process.env.SERVER ? onServer : props.children}</>
);

React.useLayoutEffect = React.useEffect;
export default React;
