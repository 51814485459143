import { useContext } from "react";
import { SessionContext } from "custom-providers/SessionProvider";
import { processEnvServer } from "hooks/useSsrHooks";
import { memoryStorage } from "utils/analytics/storage";
import { useRhUserAtomValue, useUserSessionAtomValue } from "hooks/atoms";
import isEmpty from "lodash.isempty";

export const useSession = () => {
  let {
    authenticated,
    cartProjection,
    cookiePreferences,
    currentCartId,
    deleteSession,
    isAutoMemberShipAdded,
    loading,
    location,
    membershipInfo,
    refetchCartProjection,
    refetchGetUserForSession,
    rhUser,
    rhuid,
    sessionId,
    setUserSession,
    syncRefetchGetUserForSession,
    validAssociate,
    currentCurrency,
    /* Used For Concierge Shared Components */
    selectedGallery,
    setIsNewClient,
    selectedCustomer,
    setSelectedCustomer
  } = useContext(SessionContext);
  const { userType } = useRhUserAtomValue();
  const sessionRhUser = useUserSessionAtomValue();

  if (!processEnvServer) {
    memoryStorage.setItem("analytics-sessionDetails", {
      authenticated,
      rhUser: isEmpty(rhUser) ? sessionRhUser?.rhUser : rhUser,
      membershipInfo,
      cookiePreferences
    });
  }

  const userTypes = ["REGISTERED", "TRADE", "CONTRACT"];
  const authStatus =
    (authenticated || (userType && userTypes.includes(userType))) ?? false;

  return {
    authenticated: authStatus,
    cartId: currentCartId,
    cartProjection,
    cookiePreferences,
    currentCartId,
    deleteSession,
    email: rhUser?.email,
    firstName: rhUser?.firstName,
    isAutoMemberShipAdded,
    lastName: rhUser?.lastName,
    loading,
    location,
    membershipInfo,
    refetchCartProjection,
    refetchGetUserForSession,
    rhUser,
    rhuid,
    sessionId,
    setUserSession,
    syncRefetchGetUserForSession,
    userId: rhuid || rhUser?.id,
    userType,
    validAssociate,
    currentCurrency,
    /* Used For Concierge Shared Components */
    selectedGallery: {} as any,
    setIsNewClient: (() => {}) as any,
    selectedCustomer,
    setSelectedCustomer: (() => {}) as any
  };
};

export default useSession;
