import { useState, useCallback } from "react";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useIsoCookies } from "./useIsoCookies";

interface SingleImage {
  imgSrc: string;
  handleImageLoadErr: () => void;
}

export const useImgUrl = (
  img: string,
  altImages: string[],
  version?: string
): SingleImage => {
  const [imgSrc, setImgSrc] = useState<string>(img);
  const [goodImageIndex, setGoodImageIndex] = useState(-1);
  const nextgenCookie = useIsoCookies(["nextgenpg"], true)?.nextgenpg;

  useDidMountEffect(() => {
    setImgSrc(altImages[goodImageIndex]);
  }, [goodImageIndex]);

  const handleImageLoadErr = useCallback(() => {
    if (goodImageIndex < altImages.length - 1) {
      setGoodImageIndex(goodImageIndex + 1);
    }
  }, [goodImageIndex]);

  return {
    imgSrc: version === "nextgen" || nextgenCookie === "true" ? img : imgSrc,
    handleImageLoadErr
  };
};

interface CarouselImages {
  imageList: ProductAlternateImage[];
  handleImageLoadErr: (i: number | undefined) => void;
}

export const useCarouselImgUrl = (
  images: ProductAlternateImage[]
): CarouselImages => {
  const [imageList, setImageList] = useState<ProductAlternateImage[]>(images);

  const handleImageLoadErr = useCallback(
    i => {
      const copy = [...imageList];
      copy.splice(i, 1);
      if (copy.length === 0) {
        copy.push({
          imageUrl: `//media.restorationhardware.com/is/image/rhis/ImageUnavailable_Modern?wid=2000&qlt=80`,
          caption: "Image Unavailable",
          video: ""
        });
      }
      setImageList(copy);
    },
    [imageList]
  );

  return { imageList, handleImageLoadErr };
};
