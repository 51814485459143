import { ApolloError, useMutation } from "@apollo/client";
import { mutationSubscribeToBrands } from "graphql-client/queries/email-subscription";
import analyticsLoader from "analytics/loader";
import { useCountry } from "hooks/useCountry";
import { useCallback } from "react";
import useLocale from "hooks-use-locale/useLocale";

export function useSubscribeToBrands({
  onCompleted,
  onError
}: {
  onCompleted?: () => void;
  onError?: (error: ApolloError) => void;
} = {}) {
  const country = useCountry();
  const locale = useLocale();
  const [subscribeToBrands, { loading, error }] = useMutation<
    Mutation,
    MutationSubscribeToBrandsArgs
  >(mutationSubscribeToBrands, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onCompleted: data => {
      if (onCompleted) onCompleted();
      try {
        if (data?.subscribeToBrands) {
          const brands = Object.entries(data?.subscribeToBrands).filter(
            brand => brand[1] === true
          );
          const brandsName = brands.map(name => name[0]).join(",");
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.EMAIL_SUBSCRIPTION.INT_TYPE,
              {
                brands: {
                  subscribedbrands: brandsName
                },
                isEmailMarketingFooter: true
              }
            )
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    onError: onError
  });

  const handleSubscribeToBrands: typeof subscribeToBrands = useCallback(
    (options = {}) => {
      return subscribeToBrands({
        ...options,
        variables: {
          country: country,
          locale,
          brands: options?.variables?.brands || ["RH"],
          email: options?.variables?.email || "EMAIL_MISSING",
          ...(options?.variables || {})
        }
      });
    },
    [country, subscribeToBrands, locale]
  );

  return {
    loading,
    error,
    subscribeToBrands: handleSubscribeToBrands
  };
}
