import { useMemo } from "react";
import { useLocalization } from "./useLocalization";

export type CountryCode = "en" | "fr" | "de" | "nl";

/**
 * Use Language
 *
 * Gets it from the locale useLocalization
 */
export function useLanguage() {
  const prefix = useLocalization();
  return useMemo<string>(() => {
    try {
      const [, , lang] = prefix.split("/");
      return lang ?? "en";
    } catch (error) {
      console.error(error);
      return "en";
    }
  }, [prefix]);
}
