import { useEffect, useState } from "react";
import { useResize } from "hooks/useResize";

/* This can be useful for calculating true view height on mobile devices
where the "vh" CSS property includes the native bottom bar. */

export const useViewHeight = () => {
  const [VH, setVH] = useState((window?.innerHeight ?? 0) * 0.01);
  const { height } = useResize();
  useEffect(() => {
    setVH(height * 0.01);
  }, [height]);

  return VH;
};
