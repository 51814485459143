import { useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { isValidPostalCode } from "utils/postalcode-validation";
import { isValidPhone } from "utils/phoneHelpers";

export const useFormValidators = () => {
  useEffect(() => {
    ValidatorForm.addValidationRule("isValidPostalCode", value => {
      const postalCode = value as string;
      if (postalCode && postalCode.length > 0) {
        return (
          isValidPostalCode(postalCode, "US") ||
          isValidPostalCode(postalCode, "CA") ||
          isValidPostalCode(postalCode, "GB")
        );
      }
      return true;
    });

    ValidatorForm.addValidationRule("isPhone", value => {
      return isValidPhone(value);
    });

    // returned function will be called on component unmount
    return () => {
      ValidatorForm.removeValidationRule("isValidPostalCode");
      ValidatorForm.removeValidationRule("isPhone");
    };
  }, []); // empty dependencies is same as component did mount
};

export default useFormValidators;
