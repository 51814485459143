import { useEnv } from "hooks/useEnv";
import { useNewURLStructureParams } from "hooks/useParams";
import buildPath from "utils/buildPath";
import yn from "yn";

const useGeneratePath = (saleContextFilter, data) => {
  const env = useEnv();
  const newURLStructureParams = useNewURLStructureParams();

  const generatePath = (saleContextFilter, data) => {
    const buildPathOption = {
      fromCG: "true"
    };
    const pageTitle = data?.displayName?.toLowerCase().replace(/\s+/g, "-");
    if (yn(env.FEATURE_URL_CHANGE)) {
      return buildPath(`/${newURLStructureParams?.category}/pg/${pageTitle}`, {
        ...buildPathOption
      });
    }

    // call buildPath with pgterm if feature flag is true
    if (yn(env.FEATURE_AUTOMATE_SEARCH_PGS)) {
      return buildPath(
        `/catalog/category/products.jsp/${data?.id}/category:${data?.id}`,
        {
          ...buildPathOption
        }
      );
    } else {
      return buildPath(`/catalog/category/products.jsp/${data?.id}`, {
        ...buildPathOption
      });
    }
  };

  return generatePath(saleContextFilter, data);
};

export default useGeneratePath;
