import { Dispatch, SetStateAction, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { queryMembership } from "graphql-client/queries/user";
import { removeMembershipMutation } from "graphql-client/queries/remove-membership";
import { restoreMembershipMutation } from "graphql-client/queries/membership";
import { addMembershipMutation } from "graphql-client/queries/add-membership";
import { useCountry } from "hooks/useCountry";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useEnv } from "hooks/useEnv";
import useErrorDialog from "hooks/useErrorDialog";
import { useLocalization } from "hooks/useLocalization";
import { countries } from "resources/countries-config.json";
import yn from "yn";
import { updateSessionMutation } from "graphql-client/queries/user";
import {
  useUserSessionAtomValue,
  useRhUserAtomValue,
  useUserSessionSetAtom
} from "hooks/atoms";
import {
  useGetCartProjectLazyQuery,
  useUserSessionLazyQuery
} from "hooks/queries";

export interface UseMembershipOptions {
  skip?: boolean;
  onCompleted?: (data: MembershipType | null) => void;
}

export interface UseMembershipMutaionOption {
  membership?: MembershipType | null;
  onRestoreMembershipCompleted?: (
    data: Pick<Mutation, "restoreMembership">
  ) => void;
  cartType?: boolean;
  onJoinMembership?: () => void;
  removeMembershipHandler?: () => void;
  onLoading?: Dispatch<SetStateAction<Boolean>>;
  isAutoMembershipAdd?: boolean;
  refetchGetCart?: () => void;
  setMembershipLoading?: Dispatch<SetStateAction<Boolean>>;
}

export function useMembership({
  skip,
  onCompleted
}: UseMembershipOptions = {}) {
  const { loading: loadingSession } = useUserSessionAtomValue();
  const { id: userId, userType } = useRhUserAtomValue();

  const country = useCountry();
  const { loading, error, data, refetch } = useQuery<
    Pick<Query, "membership">,
    QueryMembershipArgs
  >(queryMembership, {
    variables: {
      userId, // UserV3
      region: countries?.[country]?.region
    },
    skip: skip || userType === "ANONYMOUS" || loadingSession || !userId,
    notifyOnNetworkStatusChange: true,
    onError: () => {},
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (onCompleted && !data?.membership?.errorMessage) {
        onCompleted(data?.membership);
      }
    }
  });
  const membership = useMemo(() => {
    if (data?.membership?.errorMessage) {
      return null;
    } else {
      return data?.membership;
    }
  }, []);
  return { loading, membership, error, refetch };
}

export function useMembershipMutation({
  membership,
  onRestoreMembershipCompleted,
  cartType,
  onJoinMembership,
  removeMembershipHandler,
  onLoading,
  isAutoMembershipAdd = false,
  refetchGetCart,
  setMembershipLoading
}: UseMembershipMutaionOption = {}) {
  const env = useEnv();
  const history = useHistory();
  const country = useCountry();
  const prefix = useLocalization();
  const { rhuid, currentCartId: cartId, rhUser } = useUserSessionAtomValue();
  const [getUserSession] = useUserSessionLazyQuery();
  const [getCartProjection] = useGetCartProjectLazyQuery();
  const setSessionAtom = useUserSessionSetAtom();
  const { isAutoMemberShipAdded } = useUserSessionAtomValue();
  const { showError } = useErrorDialog();
  const pc = useIsoCookies(["pc"], true)?.pc;
  const variables = {
    email: rhUser?.email,
    cartId,
    ...(yn(env.FEATURE_ADD_ITEM_CREATE_CART)
      ? {
          createCartInfo: {
            guest: {
              email: rhUser?.email || "",
              userId: rhUser?.id
            },
            postalCode: pc,
            country
          }
        }
      : null)
  };

  const [restoreMembership, { loading: restoreMembershipLoading }] =
    useMutation<
      Pick<Mutation, "restoreMembership">,
      MutationRestoreMembershipArgs
    >(restoreMembershipMutation, {
      variables: {
        userId: rhUser?.id,
        contactId: rhUser?.sfContactId,
        region: countries?.[country]?.region,
        membershipId: membership?.membershipId,
        membershipUuid: rhuid
      },
      onError: showError,
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onCompleted: async data => {
        if (onRestoreMembershipCompleted) {
          onRestoreMembershipCompleted(data);
        }

        getUserSession();
      }
    });

  const [updateSession] = useMutation<Mutation>(updateSessionMutation, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onCompleted: ({ updateSession }) => {
      setSessionAtom(prevData => ({ ...prevData, ...updateSession }));
    }
  });

  /* add membership to cart query */
  const [addMembershipItemsToCart, { loading: addMembershipLoading }] =
    useMutation<Mutation>(addMembershipMutation, {
      variables,
      onCompleted: async () => {
        if (!isAutoMemberShipAdded) {
          await updateSession({
            variables: {
              sessionInput: [
                {
                  key: "isAutoMemberShipAdded",
                  value: "true"
                }
              ]
            }
          });
        }
        /* refetch session and cart projection */
        await getUserSession();
        getCartProjection();
        setTimeout(() => {
          refetchGetCart?.();
          setMembershipLoading?.(false);
        }, 500);

        if (cartType) {
          /* if become a membership dialog */
          if (onJoinMembership) {
            onJoinMembership();
          }
        } else if (!isAutoMembershipAdd) {
          /* push to cart page if added from account */
          history.push(`${prefix}/checkout/shopping_cart.jsp`);
        }
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onError: error => {
        // showError(error, membershipHandler);
      }
    });

  /* remove membership query */
  const [removeMembership, { loading: removeMembershipLoading }] = useMutation(
    removeMembershipMutation,
    {
      onCompleted: async () => {
        getCartProjection();
        if (removeMembershipHandler) {
          removeMembershipHandler();
        }
        await getUserSession();
        setTimeout(() => {
          refetchGetCart?.();
          setMembershipLoading?.(false);
        }, 500);
        if (onLoading) {
          onLoading(false);
        }
      },
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onError: showError
    }
  );

  return {
    removeMembership,
    removeMembershipLoading,
    addMembershipItemsToCart,
    addMembershipLoading,
    restoreMembership,
    restoreMembershipLoading
  };
}
