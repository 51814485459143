import { useEnv } from "hooks/useEnv";
import { useNewURLStructureParams } from "hooks/useParams";
import buildPath from "utils/buildPath";
import yn from "yn";

const useGetPDPRedirectPath = data => {
  const env = useEnv();
  const { category } = useNewURLStructureParams();
  const formattedProductName = data?.displayName
    ?.toLowerCase()
    .replace(/\s+/g, "-");
  const isNewURLFeatureEnabled = yn(env.FEATURE_URL_CHANGE);

  if (isNewURLFeatureEnabled && category) {
    return buildPath(`/${category}/pdp/${formattedProductName}`);
  } else {
    return buildPath(`/catalog/product/product.jsp/${data?.id}`);
  }
};

export default useGetPDPRedirectPath;
