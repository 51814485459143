import { getPriceUserType, getUrl } from "component-product-grid";
import { useMemo, useCallback } from "react";
import { currencySymbolToCurrencyCodeMapper } from "resources/countries-config.json";

const usePGProductItemList = ({
  hostName,
  prefix,
  isStockedFilterActive,
  isRefinementFilterActive,
  totalNumRecs,
  isSale,
  isSaleFilterEnabled,
  isConcierge,
  search,
  nextgenCookie,
  productList,
  rhUser
}) => {
  const getProductListItemUrl = useCallback(
    product => {
      return (
        hostName +
        prefix +
        getUrl(
          product,
          "",
          isStockedFilterActive,
          isRefinementFilterActive,
          totalNumRecs,
          Boolean(isSale || isSaleFilterEnabled),
          isConcierge,
          search?.filterQueries,
          null,
          nextgenCookie === "true",
          search?.inStockFlow
        )?.to
      );
    },
    [
      hostName,
      prefix,
      isStockedFilterActive,
      isRefinementFilterActive,
      totalNumRecs,
      isSale,
      isSaleFilterEnabled,
      isConcierge,
      search?.filterQueries,
      nextgenCookie,
      search?.inStockFlow
    ]
  );

  const metaProductItemList = useMemo(() => {
    const isNewPriceComponent =
      !isStockedFilterActive && search?.inStockFlow
        ? true
        : !!!search?.inStockFlow;

    return productList.map((product, index) => {
      const {
        product: {
          displayName,
          imageUrl,
          altImageUrl,
          priceInfo,
          skuPriceInfo,
          percentSaleSkus
        }
      } = product;

      const showSaleMessage =
        Number(percentSaleSkus) !== 0 && Number(percentSaleSkus) !== 100;
      const onSale = isNewPriceComponent
        ? priceInfo?.nextGenDrivenOnSale
        : skuPriceInfo?.onSale;

      const isOnSale =
        isSaleFilterEnabled && onSale ? true : onSale && !showSaleMessage;

      const lowPrice = isOnSale
        ? priceInfo?.skulowestMemberPrice
        : priceInfo?.memberPrices?.[0];

      return {
        position: index + 1,
        name: displayName,
        image: "https:" + (imageUrl || altImageUrl),
        lowPrice: isNewPriceComponent
          ? lowPrice
          : getPriceUserType(rhUser.userType, skuPriceInfo),
        highPrice: isNewPriceComponent
          ? priceInfo?.listPrices?.[0]
          : skuPriceInfo?.listPrice,
        currencySymbol:
          currencySymbolToCurrencyCodeMapper[priceInfo?.currencySymbol] ||
          "USD",
        url: getProductListItemUrl(product)
      };
    });
  }, [
    productList,
    isSaleFilterEnabled,
    isStockedFilterActive,
    search?.inStockFlow,
    rhUser?.userType,
    getProductListItemUrl
  ]);

  return { productItemList: metaProductItemList };
};

export default usePGProductItemList;
