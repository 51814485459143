import { useRhUserAtomValue } from "hooks/atoms";

export const TRADE = "TRADE";
export const CONTRACT = "CONTRACT";

/**
 * # Use Is Trade OR Contract HOOK
 * An easy way to tell if the user is TRADE or CONTRACT by taking advantage of useSession
 *
 * @returns react STATE boolean
 */
export const useIsTradeOrContract = (): boolean => {
  const { userType } = useRhUserAtomValue();
  return [TRADE, CONTRACT].includes(userType?.toUpperCase() ?? "ANONYMOUS");
};
