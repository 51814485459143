import { processEnvServer } from "hooks/useSsrHooks";

const featureResolvers = {
  imgLoading: () => {
    if (processEnvServer) {
      // If we need to check browser for feature browser support, it would look like this:
      // const req: express.Request | { [key: string]: any } =
      //   useContext(UseRequestProvider);
      // return !req.headers.userAgent.toLowerCase().includes("safari");
      return true;
    } else {
      return "loading" in HTMLImageElement.prototype;
    }
  }
};

export const useCheckBrowserSupport = (feature: string) =>
  featureResolvers[feature];
