import { useCallback, useContext } from "react";
import { UseRequestProvider } from "custom-providers/Provider";
import { useCookies } from "react-cookie";
import { processEnvServer } from "hooks/useSsrHooks";
import type express from "express";
import { getCookie } from "utils/cookies";

type Cookies = {
  [name: string]: any;
};

export function useIsoCookies<C extends Cookies>(
  cookieFilter?: (keyof C)[],
  useDirectCookies?: boolean
): C;

export function useIsoCookies(
  cookieFilter?: string[],
  useDirectCookies?: boolean
): Cookies {
  const req: express.Request | { [key: string]: any } = processEnvServer
    ? useContext(UseRequestProvider)
    : {};

  const getDirectCookies = useCallback(() => {
    const directCookies = cookieFilter?.reduce(
      (acc, cookie) => ({ ...acc, [cookie]: getCookie(cookie) }),
      {}
    );
    return [directCookies];
  }, [cookieFilter]);

  const [cookies] = processEnvServer
    ? [
        cookieFilter
          ? Object.assign(
              {},
              ...Object.entries(req.cookies)
                .filter(([k]) => cookieFilter.includes(k))
                .map(([k, v]) => ({ [k]: v }))
            )
          : req.cookies
      ]
    : useDirectCookies
    ? getDirectCookies()
    : useCookies(cookieFilter);
  return cookies;
}
