import { useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { userSessionAtom } from "./useUserSessionAtom";
import Cookies from "js-cookie";
import { USER_COOKIE_PREFERENCES } from "utils/constants";

const cookiePreferencesAtom = selectAtom(userSessionAtom, userSession => {
  try {
    return (
      userSession?.cookiePreferences ??
      JSON.parse(Cookies.get(USER_COOKIE_PREFERENCES))
    );
  } catch (e) {
    return null;
  }
});

export const useCookiePreferencesAtomValue = () =>
  useAtomValue(cookiePreferencesAtom);
