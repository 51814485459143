import { atom, useAtomValue } from "jotai";
import { userSessionAtom, selectedGalleryAtom } from "hooks/atoms";

const useFeatureBUFAtom = atom(get =>
  get(selectedGalleryAtom)?.activePilots?.includes("BUF")
    ? get(userSessionAtom)?.rhUser?.galleryPilotOptIn
    : false
);

export const useFeatureBUFAtomValue = () => useAtomValue(useFeatureBUFAtom);
