import { useState, useCallback, useMemo } from "react";

const useToggle = (defaultValue: boolean = false): [boolean, () => void] => {
  const [status, setStatus] = useState(defaultValue);
  const toggleStatus = useCallback(() => {
    setStatus(prevStatus => !prevStatus);
  }, []);

  return useMemo(() => [status, toggleStatus], [status, toggleStatus]);
};

export default useToggle;
