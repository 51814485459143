import { useApolloClient } from "@apollo/client";
import { queryCartProjection } from "graphql-client/queries/cart-projection";
import { useCartProjectionSetAtom } from "hooks/atoms";
import { useRhUserAtomValue } from "hooks/atoms";
import { useCallback } from "react";

export const useGetCartProjectLazyQuery = () => {
  const client = useApolloClient();

  const { id, email } = useRhUserAtomValue();
  const setCartProjectionAtom = useCartProjectionSetAtom();

  const getCartProjectionWrapper = useCallback(
    async (variables = {}) => {
      /** if user id is not defined don't allow this call */
      if (!Boolean(variables?.id ?? id)) {
        return;
      }
      try {
        const { data } = await client?.query({
          query: queryCartProjection,
          variables: {
            /** empty userId should throw graphql error */
            userId: variables?.id ?? id ?? null,
            email: variables?.email ?? email ?? null,
            // queryTimeStamp is added to ensure to the data is not coming from disk cache
            queryTimeStamp: String(Date.now())
          }
        });

        setCartProjectionAtom(data?.cartProjection);
      } catch (err) {
        console?.error(err);
      }
    },
    [client, id, email, setCartProjectionAtom]
  );

  return [getCartProjectionWrapper] as const;
};

export default useGetCartProjectLazyQuery;
