import { useMutation } from "@apollo/client";
import { deleteSessionMutation } from "graphql-client/queries/session";
import { useKeycloak } from "utils/Keycloak/KeyCloak";

export const useDeleteSessionMutation = () => {
  const { keycloak } = useKeycloak();

  return useMutation<Mutation>(deleteSessionMutation, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onError() {
      keycloak?.logout();
    },
    onCompleted() {
      keycloak?.logout();
    }
  });
};

export default useDeleteSessionMutation;
