import { processEnvServer } from "hooks/useSsrHooks";
import { atom, useSetAtom, useAtomValue, useAtom } from "jotai";
import { memoryStorage } from "utils/analytics/storage";
import { useKeycloak } from "utils/Keycloak/KeyCloak";

export const userSessionAtom = atom<
  SessionUserType & {
    loading: boolean;
    lastRequestId?: string;
    lastRequestIdToCompare?: string;
  }
>({
  loading: true,
  lastRequestId: "",
  lastRequestIdToCompare: "",
  rhUser: {
    userType: "ANONYMOUS"
  }
});

export const useUserSessionAtomValue = () => {
  const userSession = useAtomValue(userSessionAtom);
  const { keycloak } = useKeycloak();

  if (!processEnvServer) {
    const storedSessionDetails = memoryStorage.getItem(
      "analytics-sessionDetails"
    );
    const hasUniqueValues =
      storedSessionDetails?.authenticated !== keycloak?.authenticated ||
      storedSessionDetails?.rhUser !== userSession?.rhUser ||
      storedSessionDetails?.membershipInfo !== userSession?.membershipInfo ||
      storedSessionDetails?.cookiePreferences !==
        userSession?.cookiePreferences;
    if (hasUniqueValues) {
      memoryStorage.setItem("analytics-sessionDetails", {
        authenticated: keycloak?.authenticated,
        rhUser: userSession?.rhUser,
        membershipInfo: userSession?.membershipInfo,
        cookiePreferences: userSession?.cookiePreferences
      });
    }
  }

  return userSession;
};

export const useUserSessionSetAtom = () => useSetAtom(userSessionAtom);
export const useUserSessionAtom = () => useAtom(userSessionAtom);

export default useUserSessionAtom;
