import { useMemo } from "react";
import { hreflangOptions } from "resources/countries-config.json";
import { useLocalization } from "hooks/useLocalization";

const useLocale = () => {
  const urlPrefix = useLocalization();
  return useMemo(
    () =>
      hreflangOptions.find(option => option.locale === `${urlPrefix}/`)
        ?.hreflang || "en-US",
    [urlPrefix]
  );
};

export default useLocale;
