import { usePageContent } from "customProviders/LocationProvider";
import { useCallback } from "react";
import countriesConfig from "resources/countries-config.json";
import { EnhancedTextType } from "utils/buildReactNodesFromArray";
import { europeanCountries } from "resources/countries-config.json";
import { UK } from "utils/constants";
import { formatCurrency } from "utils/currencyHelpers";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import useLocale from "hooks/useLocale/useLocale";
import { useFetchModel } from "hooks/useFetchModel";

const TextType = "test";
const BoldType = "b";
export function useCartMessages({ removeShippingChargeOverride = false } = {}) {
  const { pageContent: pageBased } = usePageContent();
  const { pageContent: cartPage } = useFetchModel("shopping-cart", true, false);
  const pageContent = { ...cartPage, ...pageBased };
  const env = useEnv();
  const locale = useLocale();
  const removeShippingAmount =
    yn(env.FEATURE_CART_REMOVE_SHIPPING_AMOUNT) &&
    !removeShippingChargeOverride;

  const getFormattedDate = useCallback((date: string, country: string) => {
    const isNorthAmerica = ![...europeanCountries, UK].includes(country);
    const formattedDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      year: isNorthAmerica ? "2-digit" : "numeric",
      month: isNorthAmerica ? "2-digit" : "long",
      day: "2-digit"
    };
    return isNorthAmerica
      ? date
      : formattedDate.toLocaleDateString(locale, options).replace(".", "");
  }, []);

  const formatDate = useCallback(
    (date: string, country?: string) => {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      const normalizedDate = [month, day, year.substring(2, 4)].join("/");
      return getFormattedDate(normalizedDate, country || "US");
    },
    [getFormattedDate]
  );

  const getDeliveryInfo = useCallback(
    ({
      postalCode,
      shipViaCode,
      groundFreightExempt,
      freight,
      skuType,
      currencyCode
    }: {
      postalCode: Maybe<string>;
      shipViaCode: Maybe<string> | undefined;
      groundFreightExempt?: Maybe<boolean>;
      freight?: FreightPriceType | null;
      skuType?: string;
      currencyCode?: string;
    }) => {
      if (skuType?.includes("swatch") && !!pageContent?.swatchShipMessage) {
        return [
          {
            displayText: pageContent?.swatchShipMessage,
            type: "text"
          }
        ];
      }
      const hasPostalCode = !!postalCode && postalCode !== "null";

      const deliveryInfoEnhancedText = [] as EnhancedTextType[];

      // --- Business Rules for All the Delivery Messages ---

      // Standard Delivery Shipping
      // - Will have a ship via code of 0 or 1.
      if (shipViaCode === "0" || shipViaCode === "1") {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS
        });
        // Ships Free Via Standard Delivery Shipping
        // - Will also have its groundFreightExempt boolean set to true
        if (groundFreightExempt) {
          deliveryInfoEnhancedText.push({
            type: yn(env.FEATURE_CART_REFINEMENT_V2) ? "" : "b",
            displayText: pageContent?.FREE
          });
        }
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_STANDARD_DELIVERY_SHIPPING
        });
        // Ships Free via USPS Expedited Delivery
        // - Ship via code 9
        // - groundFreightExempt set to true
      }
      // Expedited Delivery Shipping
      // - Will have a ship via code of 2 or 3.
      else if (shipViaCode === "2" || shipViaCode === "3") {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS
        });
        // Ships Free Via Standard Delivery Shipping
        // - Will also have its groundFreightExempt boolean set to true
        if (groundFreightExempt) {
          deliveryInfoEnhancedText.push({
            type: yn(env.FEATURE_CART_REFINEMENT_V2) ? "" : "b",
            displayText: pageContent?.FREE
          });
        }
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_STANDARD_DELIVERY_SHIPPING
        });
      }
      // Ships Free via USPS Expedited Delivery
      // - Ship via code 9
      // - groundFreightExempt set to true
      else if (shipViaCode === "9" && groundFreightExempt) {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS
        });
        deliveryInfoEnhancedText.push({
          type: yn(env.FEATURE_CART_REFINEMENT_V2) ? "" : "b",
          displayText: pageContent?.FREE
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_USPS
        });
      }
      // Unlimited Furniture Delivery
      // - Ship via code 5
      // - For unlimited price you can pull this off the cart.cartPrice.freight.unlimited.
      // (This is charged once no matter how many UFD items are on the cart)
      else if (shipViaCode === "5" && hasPostalCode) {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: removeShippingAmount
            ? pageContent?.Via_Unlimited_Furniture
            : pageContent?.VIA_UNLIMITED_FURNITURE
        });
        if (!removeShippingAmount) {
          if (freight?.unlimited) {
            deliveryInfoEnhancedText.push({
              type: "b",
              displayText: formatCurrency(
                Number(freight?.unlimited) / 100,
                currencyCode,
                false
              )
            });
            deliveryInfoEnhancedText.push({
              type: TextType,
              displayText: pageContent?.FOR
            });
          }
          deliveryInfoEnhancedText.push({
            type: TextType,
            displayText: postalCode
          });
        }
      }
      // Unlimited Furniture Delivery White Glove
      // - Ship via code 6
      else if (shipViaCode === "6" && hasPostalCode) {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          // displayText: "via Unlimited Furniture Delivery White Glove "
          displayText: removeShippingAmount
            ? pageContent?.Via_Unlimited_Furniture
            : pageContent?.VIA_UNLIMITED_FURNITURE
        });
        if (!removeShippingAmount) {
          if (freight?.unlimited) {
            deliveryInfoEnhancedText.push({
              type: "b",
              displayText: formatCurrency(
                Number(freight?.unlimited) / 100,
                currencyCode,
                false
              )
            });
            deliveryInfoEnhancedText.push({
              type: TextType,
              displayText: pageContent?.FOR
            });
          }
          deliveryInfoEnhancedText.push({
            type: TextType,
            displayText: postalCode
          });
        }
      }
      // Unlimited Furniture Delivery Curbside
      // - Ship via code 10
      // (This one is oversize and triggers the display of the oversize item copy)
      else if (shipViaCode === "10" && hasPostalCode) {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_UNLIMITED_CURBSIDE
        });
        if (!removeShippingAmount) {
          deliveryInfoEnhancedText.push({
            type: "b",
            displayText: formatCurrency(
              Number(freight?.unlimited) / 100,
              currencyCode,
              false
            )
          });
          deliveryInfoEnhancedText.push({
            type: TextType,
            displayText: pageContent?.FOR + postalCode
          });
        }
      }

      return deliveryInfoEnhancedText;
    },
    [pageContent]
  );

  const getDeliveryInfoForCart = useCallback(
    ({
      cart,
      shipViaCode,
      groundFreightExempt
    }: {
      cart: CartType;
      shipViaCode: string;
      groundFreightExempt?: boolean;
    }) => {
      const hasPostalCode = !!cart?.postalCode && cart?.postalCode !== "null";

      const deliveryEstimate = `${
        countriesConfig?.currencyCountryMapper?.[
          cart?.cartPrice?.currencyCode ?? "USD"
        ]
      }${Number((cart?.cartPrice?.freight?.unlimited ?? 0) / 100).toFixed(2)}`;

      const deliveryInfoEnhancedText = [] as EnhancedTextType[];
      if (shipViaCode === "0" || shipViaCode === "1") {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS + " "
        });
        if (groundFreightExempt) {
          deliveryInfoEnhancedText.push({
            type: BoldType,
            displayText: pageContent?.FREE + " "
          });
        }
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_STANDARD_DELIVERY_SHIPPING
        });
      } else if (shipViaCode === "2" || shipViaCode === "3") {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS + " "
        });
        if (groundFreightExempt) {
          deliveryInfoEnhancedText.push({
            type: BoldType,
            displayText: pageContent?.FREE + " "
          });
        }
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_STANDARD_DELIVERY_SHIPPING
        });
      } else if (shipViaCode === "9" && groundFreightExempt) {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS + " "
        });
        deliveryInfoEnhancedText.push({
          type: BoldType,
          displayText: pageContent?.FREE + " "
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_USPS_EXPEDITED_DELIVERY
        });
      } else if (shipViaCode === "5" && hasPostalCode) {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS + " "
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_UNLIMITED_FURNITURE_DELIVERY_FOR + " "
        });

        if (cart?.cartPrice?.freight?.unlimited) {
          deliveryInfoEnhancedText.push({
            type: BoldType,
            displayText: deliveryEstimate
          });
          deliveryInfoEnhancedText.push({
            type: TextType,
            displayText: " " + pageContent.FOR + " "
          });
        }
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: cart?.postalCode || "PC_MISSING"
        });
      } else if (shipViaCode === "6" && hasPostalCode) {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS + " "
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.VIA_UNLIMITED_FURNITURE_DELIVERY_FOR + " "
        });
        if (cart?.cartPrice?.freight?.unlimited) {
          deliveryInfoEnhancedText.push({
            type: BoldType,
            displayText: deliveryEstimate
          });
          deliveryInfoEnhancedText.push({
            type: TextType,
            displayText: " " + pageContent.FOR + " "
          });
        }
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: cart?.postalCode || "PC_MISSING"
        });
      } else if (shipViaCode === "10" && hasPostalCode) {
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: pageContent?.SHIPS + " "
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText:
            pageContent?.VIA_UNLIMITED_FURNITURE_DELIVERY_CURBSIDE + " "
        });
        deliveryInfoEnhancedText.push({
          type: BoldType,
          displayText: deliveryEstimate
        });
        deliveryInfoEnhancedText.push({
          type: TextType,
          displayText: " " + pageContent.FOR + " " + cart?.postalCode
        });
      }

      return deliveryInfoEnhancedText;
    },
    [pageContent]
  );

  const getAvailabilityInfo = useCallback(
    ({
      spoTermsAccepted,
      deliveryEtaStart,
      deliveryEtaEnd,
      deliveryEta,
      availabilityStatus,
      cartCountry = "US",
      propPageContent = {}
    }: {
      spoTermsAccepted: Maybe<boolean> | undefined;
      deliveryEtaStart: Maybe<string>;
      deliveryEtaEnd: Maybe<string>;
      deliveryEta: Maybe<string>;
      availabilityStatus: Maybe<string>;
      cartCountry?: string;
      propPageContent?: any;
    }) => {
      const availabilityInfo = [] as EnhancedTextType[];
      const localPageContent = pageContent || propPageContent;
      if (
        (spoTermsAccepted ||
          availabilityStatus?.toLowerCase() === "dropship") &&
        deliveryEtaEnd &&
        deliveryEtaStart
      ) {
        availabilityInfo.push({
          type: TextType,
          displayText:
            localPageContent?.ITEM_SPO_DELIVERY_BETWEEN +
            formatDate(deliveryEtaStart, cartCountry) +
            localPageContent?.AND_SPACED +
            formatDate(deliveryEtaEnd, cartCountry)
        });
      } else if (
        (spoTermsAccepted ||
          availabilityStatus?.toLowerCase() === "dropship") &&
        !deliveryEtaStart &&
        deliveryEta
      ) {
        availabilityInfo.push({
          type: TextType,
          displayText:
            localPageContent?.ITEM_SPO_DELIVERY_ON_OR_BEFORE +
            formatDate(deliveryEta, cartCountry)
        });
      } else if (
        availabilityStatus?.toLowerCase() === "instock" &&
        deliveryEtaEnd &&
        deliveryEtaStart
      ) {
        availabilityInfo.push({
          type: TextType,
          displayText:
            localPageContent?.ITEM_STOCK_DELIVERY_BETWEEN +
            formatDate(deliveryEtaStart, cartCountry) +
            localPageContent?.AND_SPACED +
            formatDate(deliveryEtaEnd, cartCountry)
        });
      } else if (
        availabilityStatus?.toLowerCase() === "instock" &&
        !deliveryEtaStart &&
        deliveryEta
      ) {
        availabilityInfo.push({
          type: TextType,
          displayText:
            localPageContent?.ITEM_STOCK_DELIVERY_ON_OR_BEFORE +
            formatDate(deliveryEta, cartCountry)
        });
      } else if (
        availabilityStatus?.toLowerCase() === "backorder" &&
        deliveryEtaStart &&
        deliveryEtaEnd
      ) {
        availabilityInfo.push({
          type: TextType,
          displayText:
            localPageContent?.ITEM_READY_DELIVERY_BETWEEN +
            formatDate(deliveryEtaStart, cartCountry) +
            localPageContent?.AND_SPACED +
            formatDate(deliveryEtaEnd, cartCountry)
        });
      } else if (
        availabilityStatus?.toLowerCase() === "backorder" &&
        !deliveryEtaStart &&
        deliveryEta
      ) {
        availabilityInfo.push({
          type: TextType,
          displayText:
            localPageContent?.ITEM_READY_DELIVERY_ON_OR_BEFORE +
            formatDate(deliveryEta, cartCountry)
        });
      } else if (!deliveryEtaStart && deliveryEta) {
        availabilityInfo.push({
          type: TextType,
          displayText:
            localPageContent?.ITEM_STOCK_DELIVERY_ON_OR_BEFORE +
            formatDate(deliveryEta, cartCountry)
        });
      } else if (deliveryEtaStart && deliveryEtaEnd) {
        availabilityInfo.push({
          type: TextType,
          displayText:
            localPageContent?.ITEM_READY_DELIVERY_BETWEEN +
            formatDate(deliveryEtaStart, cartCountry) +
            localPageContent?.AND_SPACED +
            formatDate(deliveryEtaEnd, cartCountry)
        });
      }
      return availabilityInfo;
    },
    [formatDate, pageContent]
  );

  const getImportantInformationNA = useCallback(
    (country: string) => {
      const availabilityInfo = [] as EnhancedTextType[];

      if (country === "CA") {
        availabilityInfo.push({
          type: "CAD",
          displayText: pageContent?.ALL_PRICES_ARE_SHOWN_IN_CANADIAN_DOLLARS
        });
      }

      availabilityInfo.push({
        type: "default",
        displayText: pageContent?.ITEMS_WILL_BE_DELIVERED_AS_THEY_ARE_AVAILABLE
      });

      return availabilityInfo;
    },
    [pageContent]
  );

  const getImportantInformation = useCallback(
    (cart: CartType) => {
      const cartCountry = cart?.country || "US";
      if (cart?.coordGroupType === "preferred") {
        const availabilityInfo: EnhancedTextType[] = [];

        if (cartCountry === "CA") {
          availabilityInfo.push({
            type: "CAD",
            displayText: pageContent?.ALL_PRICES_ARE_SHOWN_IN_CANADIAN_DOLLARS
          });
        }
        availabilityInfo.push({
          type: "consolidate",
          displayText: pageContent?.FURNITURE_ITEMS_WILL_BE_GROUPED
        });
        return availabilityInfo;
      } else {
        // Important Information based on country (US or Canada)
        return getImportantInformationNA(cartCountry || "US");
      }
    },
    [getImportantInformationNA, pageContent]
  );

  return {
    getDeliveryInfo,
    getDeliveryInfoForCart,
    getAvailabilityInfo,
    getImportantInformation
  };
}
