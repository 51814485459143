import { useEffect } from "react";
import { processEnvServer } from "hooks/useSsrHooks";

/** Recovery from  "ChunkLoadError: Loading chunk x failed" */
export function useChunkLoadErrorRecovery() {
  useEffect(() => {
    if (!processEnvServer) {
      const listener = (error: ErrorEvent): void => {
        if (/Loading chunk [\d]+ failed/.test(error?.message)) {
          window.location.reload();
        }
      };
      window.addEventListener("error", listener);
      return () => window.removeEventListener("error", listener);
    }
  }, []);
}
