import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { useCallback } from "react";
import { useCookies as reactUseCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";
import { removeCookieOptions } from "utils/getCookieOptions";

type UseCookies = [
  {
    [x: string]: any;
  },
  (name: string, value: any, options?: CookieSetOptions | undefined) => void,
  (name: string, options?: CookieSetOptions | undefined) => void
];

export const useCookies = (deps: string[]) => {
  const [cookies, _, removeCookie] = reactUseCookies(deps);

  const { setCookieWrapper } = useCookiesWithPermission();

  const removeCookieWrapper = useCallback<typeof removeCookie>(
    name => {
      const cookieOptions = removeCookieOptions();
      removeCookie(name, cookieOptions);
    },
    [removeCookie]
  );

  return [cookies, setCookieWrapper, removeCookieWrapper] as UseCookies;
};
