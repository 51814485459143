import usePageLoadAction, { PageLoadAction } from "hooks/usePageLoadAction";
import {
  DIALOG_TRADE_REQUEST_KEY,
  DIALOG_INTERIOR_DESIGN_KEY,
  DIALOG_CUSTOM_WINDOW_KEY,
  DIALOG_REQUEST_CONSULTATION_KEY,
  DIALOG_COOKIE_MANAGEMENT_KEY
} from "component-promo/constants";

type ModalType =
  | typeof DIALOG_TRADE_REQUEST_KEY
  | typeof DIALOG_INTERIOR_DESIGN_KEY
  | typeof DIALOG_CUSTOM_WINDOW_KEY
  | typeof DIALOG_REQUEST_CONSULTATION_KEY
  | typeof DIALOG_COOKIE_MANAGEMENT_KEY;

type Actions = {
  [key in ModalType]: PageLoadAction;
};

export default (
  actions: Partial<Actions>,
  modalType: ModalType | Maybe<string> | undefined | null,
  deps?: ReadonlyArray<any>
): void => {
  let action: PageLoadAction = () => {};

  if (modalType && typeof actions[modalType as ModalType] === "function") {
    action = actions[modalType as ModalType];
  }

  usePageLoadAction(
    action,
    { matchUrl: /pfautocta=(1)/ },
    () =>
      new RegExp(
        `${DIALOG_TRADE_REQUEST_KEY}|${DIALOG_INTERIOR_DESIGN_KEY}|${DIALOG_CUSTOM_WINDOW_KEY}|${DIALOG_REQUEST_CONSULTATION_KEY}`,
        "i"
      ).test(modalType || ""),
    [modalType, ...deps]
  );
};
