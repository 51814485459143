import useBrand from "hooks-use-brand/useBrand";
import { RHUK_HOST, RH_HOST } from "utils/constants";
import useUserPreferences from "./useUserPreferences";
import { checkCountryIsEu } from "utils/checkCountryIsEu";

const useSite = () => {
  const {
    previousState: { country }
  } = useUserPreferences();

  const brand = useBrand();
  const isCoreBrand = brand === RH_HOST;
  if (isCoreBrand) {
    if (checkCountryIsEu({ country })) {
      return RHUK_HOST;
    }

    return RH_HOST;
  }

  return brand;
};

export default useSite;
