import { LazyQueryHookOptions, useApolloClient } from "@apollo/client";
import { queryGetUserForSession } from "graphql-client/queries/session";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { REQUIRED_PERMISSION_COUNTRIES } from "utils/constants";
import { useUserSessionAtomValue, useUserSessionSetAtom } from "hooks/atoms";
import useAppData from "hooks/useAppData";
import { useCallback, useMemo, useState } from "react";
import { useIsoCookies } from "hooks/useIsoCookies";
import { isValidPostalCode as getIsValidPostalCode } from "utils/postalcode-validation";
import type { CountryCode } from "hooks/useCountry";
import { useCookiePermissionBannerSetAtom } from "hooks/atoms/useCookiePermissionBannerAtom";
import { useIsCheckout } from "@RHCommerceDev/hooks/useIsCheckout/useIsCheckout";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import { v4 as uuidv4 } from "uuid";

export const useUserSessionLazyQuery = (options?: LazyQueryHookOptions) => {
  const client = useApolloClient();

  const { cookiePermissionCountry, setCookiePermissionCountry } =
    useCookiesWithPermission();

  const setPermissionCountry = useCookiePermissionBannerSetAtom();

  const { app, setApp } = useAppData();

  const setSessionAtom = useUserSessionSetAtom();
  const prevSession = useUserSessionAtomValue();

  const country = getCountryFromUrl();

  const { pc: postalCode = "" } = useIsoCookies(["pc"], true);

  const [isLoading, setIsLoading] = useState(false);

  const isValidPostalCode = useMemo(
    () => getIsValidPostalCode(postalCode, country as CountryCode),
    [country, postalCode]
  );

  const { isCheckout } = useIsCheckout();
  const env = useEnv();

  const getUserSessionQueryWrapper = useCallback(async () => {
    try {
      setIsLoading(true);
      const lastRequestId = uuidv4();
      setSessionAtom({ ...prevSession, lastRequestId, loading: true });

      const { data: { getUserForSession } = {} } = await client?.query({
        query: queryGetUserForSession,
        variables: {
          useCartBroker: true,
          postalCode: isValidPostalCode ? postalCode : "",
          country,
          /** prevent automatic cart creation. carts should be created at the time when an item or membership is added */
          createCart: !yn(env.FEATURE_ADD_ITEM_CREATE_CART),
          /** we want to avoid updating the postal code or country of the cart while we are in the checkout flow... causes address drops */
          doNotMutateCart: isCheckout,
          // queryTimeStamp is added to ensure to the data is not coming from disk cache
          queryTimeStamp: String(Date.now())
        }
      });

      if (getUserForSession?.rhUser?.postalCode) {
        setApp({ ...app, postalCode: getUserForSession.rhUser?.postalCode });
      }

      if (!cookiePermissionCountry) {
        if (
          REQUIRED_PERMISSION_COUNTRIES?.includes(
            getUserForSession?.rhUser?.akamaiCountryCode || ""
          )
        ) {
          setCookiePermissionCountry(
            getUserForSession?.rhUser?.akamaiCountryCode || ""
          );
        } else {
          setPermissionCountry(
            (getUserForSession?.cookiePreferences?.cookieRules as string) || ""
          );
        }
      }

      setSessionAtom({
        ...getUserForSession,
        loading: false,
        lastRequestIdToCompare: lastRequestId
      });

      await options?.onCompleted?.({ getUserForSession });
    } catch (err) {
      console?.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [postalCode, country, isValidPostalCode, prevSession]);

  return [getUserSessionQueryWrapper, { isLoading }] as const;
};

export default useUserSessionLazyQuery;
