import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "./useDebounce";

export const useResize = (debounceTime: number = 0) => {
  const initialWidth = useMemo(() => window.innerWidth, []);
  const [width, setWidth] = useState<number>(initialWidth || null);
  const initialHeight = useMemo(() => window.innerHeight, []);
  const [height, setHeight] = useState<number>(initialHeight || null);

  const { debounce, cancel } = useDebounce();

  useEffect(() => {
    // let debounceInstance = null;
    const listener = (event: UIEvent) => {
      debounce(debounceTime, () => {
        setWidth((event.target as typeof window).innerWidth);
        setHeight((event.target as typeof window).innerHeight);
      });
    };

    // Add initial event listener to resize action
    window.addEventListener("resize", listener);

    // Unmount
    return () => {
      // Cancel any debounces that could be pending
      cancel();
      // Remove event listener
      window.removeEventListener("resize", listener);
    };
  }, [cancel, debounce, debounceTime]);

  return {
    width,
    height
  };
};
