import { useMemo } from "react";
import { countryCurrencyMapper } from "resources/countries-config.json";
import { defaultCountryCodeFromPostalCode } from "utils/addressUtils";
import yn from "yn";
import { useEnv } from "./useEnv";

type UseCurrencyCode = {
  country: string;
  postalCode: Maybe<string>;
  userType: Maybe<string>;
};

export const useCurrencyCode = ({
  country,
  postalCode,
  userType
}: UseCurrencyCode) => {
  const env = useEnv();

  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);

  const currencyCode = useMemo(() => {
    if (FEATURE_INTERNATIONAL) {
      return countryCurrencyMapper[country];
    } else {
      return postalCode && userType !== "CONTRACT"
        ? defaultCountryCodeFromPostalCode(postalCode) === "CA"
          ? "CAD"
          : "USA" // Trust me; ATG wants this to be USA not USD
        : "USA";
    }
  }, [postalCode, userType, country, FEATURE_INTERNATIONAL]);
  return currencyCode;
};
