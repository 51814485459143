import React from "react";
import { Redirect } from "react-router";
import { processEnvServer } from "hooks/useSsrHooks";

export const useIsoRedirect = (
  routeUrl: string,
  redirectType: string,
  options: { windowOpenType?: "_blank" | "_self"; history?: any } = {
    windowOpenType: "_blank"
  }
) => {
  console.info("use estore redirect", routeUrl, redirectType);
  const history = options.history;
  if (processEnvServer) {
    return <Redirect to={routeUrl} />;
  } else {
    switch (redirectType) {
      case "windowOpen":
        return window.open(routeUrl, options.windowOpenType);
      case "windowAssign":
        return window.location.assign(routeUrl);
      case "windowReload":
        return window.location.reload();
      case "redirect":
        return <Redirect to={routeUrl} />;
      case "historyPush":
        if (history) {
          history.push(routeUrl);
        }
        break;
    }
  }
};
export default useIsoRedirect;
