import he from "he";
import { useMemo } from "react";
import { RH_CUSTOMER_NUMBER } from "utils/constants";
import { languages } from "resources/countries-config.json";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { getSupportPhoneNumberByCountry } from "utils/getSupportPhoneNumberByCountry";
import { useLocalization } from "hooks/useLocalization";
import { useEnv } from "hooks/useEnv";
import stripTags from "utils/stripTags";
import yn from "yn";

type CommonType = {
  name: string;
  description: string;
  url?: string;
};

type Item = {
  position: number;
  name: string;
  image: string;
  lowPrice: number;
  highPrice: number;
  currencySymbol: string;
  url: string;
};

type ItemListData = CommonType & {
  itemList: Item[];
};

type ProductData = CommonType & {
  RHImage: string;
  sku: string;
  mpn: string;
  brand: string;
  canonicalUrl: string;
  lowPrice: string;
  highPrice: string;
  hasInStock: boolean;
};

export function useGetSchema(type: "Product", data: ProductData): string;
export function useGetSchema(type: "ItemList", data: ItemListData): string;
export function useGetSchema(
  type: "Product" | "ItemList",
  data: ProductData | ItemListData
): string {
  return useMemo(() => {
    let schema = {};
    const decodedTitle = he.decode(stripTags(data?.name));
    const decodedDescription = he.decode(
      data?.description
        ? `${stripTags(data?.description)}`
        : "Honoring refined design and artisanal craftsmanship, our timeless collections celebrate the distinctive provenance and enduring quality of each piece."
    );

    let commonContent = {
      "@context": "https://schema.org",
      "@type": type,
      name: decodedTitle,
      description: decodedDescription
    };
    if (type === "ItemList") {
      const itemList = (data as ItemListData)?.itemList;
      schema = {
        ...commonContent,
        url: data?.url || window?.location?.href,
        itemListElement: itemList.map((item: Item) => ({
          "@type": "ListItem",
          position: item?.position,
          item: {
            "@type": "Product",
            name: item?.name,
            image: [item?.image],
            offers: {
              "@type": "AggregateOffer",
              lowPrice: item?.lowPrice,
              highPrice: item?.highPrice,
              priceCurrency: item?.currencySymbol ?? "USD"
            },
            url: item?.url
          }
        }))
      };
    } else if (type === "Product") {
      schema = {
        ...commonContent,
        image: (data as ProductData)?.RHImage,
        sku: (data as ProductData)?.sku,
        mpn: (data as ProductData)?.mpn,
        brand: {
          "@type": "Brand",
          name: (data as ProductData)?.brand
        },
        offers: {
          "@type": "AggregateOffer",
          url: (data as ProductData)?.canonicalUrl,
          priceCurrency: "USD",
          lowPrice: (data as ProductData)?.lowPrice,
          highPrice: (data as ProductData)?.highPrice,
          itemCondition: "https://schema.org/NewCondition",
          availability: (data as ProductData)?.hasInStock
            ? "https://schema.org/InStock"
            : "https://schema.org/OutOfStock",
          seller: {
            "@type": "Organization",
            name: "RH"
          }
        }
      };
    }
    return JSON.stringify(schema);
  }, [type, data]);
}

export function useGetHomeSchema() {
  const prefix = useLocalization();
  const host = window?.location?.protocol + "//" + window?.location?.host;
  const country = getCountryFromUrl();
  const language = getLanguageFromUrl();
  const env = useEnv();
  const searchPath = yn(env?.FEATURE_URL_CHANGE)
    ? "{search_term_string}"
    : "results.jsp?Ntt={search_term_string}&Ns=product.sale%7C1";
  const telephone =
    getSupportPhoneNumberByCountry(country) || RH_CUSTOMER_NUMBER;
  const selectedLanguage = useMemo(() => {
    const languageLabel = languages.find(
      lan => lan.value === language?.mapped
    )?.label;
    return languageLabel?.includes("English") ? "English" : languageLabel;
  }, [language]);
  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    url: `${host}${prefix}/`,
    name: "Restoration Hardware",
    description:
      "Restoration Hardware is a luxury brand in the home furnishings marketplace, offering furniture, lighting, textiles, rugs, bathware, décor, outdoor and garden, as well as baby & child products.",
    publisher: {
      "@type": "Organization",
      name: "Restoration Hardware",
      url: `${host}${prefix}/`,
      logo: {
        "@type": "ImageObject",
        url: `${host}/img/logos/RH_Black.svg`,
        width: 250,
        height: 50
      },
      contactPoint: {
        "@type": "ContactPoint",
        telephone: telephone,
        contactType: "Customer Service",
        areaServed: country,
        availableLanguage: selectedLanguage
      },
      sameAs: [
        "https://www.facebook.com/restorationhardware",
        "https://twitter.com/RH",
        "https://www.instagram.com/rh/",
        "https://www.linkedin.com/company/restoration-hardware/"
      ]
    },
    potentialAction: {
      "@type": "SearchAction",
      target: `${host}${prefix}/search/${searchPath}`,
      "query-input": "required name=search_term_string"
    }
  };

  return JSON.stringify(homeSchema);
}
