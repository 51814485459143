import { useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { userSessionAtom } from "./useUserSessionAtom";

const membershipInfoAtomValue = selectAtom(
  userSessionAtom,
  userSession => userSession?.membershipInfo ?? {}
);

export const useMembershipInfoAtomValue = () =>
  useAtomValue(membershipInfoAtomValue);
