import { createStyles, makeStyles, Theme } from "utils/material-ui-core";
import useRHBrand from "hooks/useRHBrand";
import {
  BC_PRIMARY_COLOR,
  MO_PRIMARY_COLOR,
  BC_SECONDARY_COLOR,
  FONT_BARON_SANS,
  MO_SECONDARY_COLOR,
  FONT_ACUMIN_PRO,
  LIGHT_GRAY,
  FONT_REGULAR,
  FONT_BOLD,
  FONT_CASLON
} from "utils/constants";

const createCommonStyles = (theme: Theme) => ({
  textCenter: { textAlign: "center" },
  fullWidth: { width: "100%" },
  maxFullWidth: { maxWidth: "100%" },
  minFullWidth: { minWidth: "100%" },
  containedTextField: {
    minWidth: `${theme.spacing(6)}px`,
    maxWidth: `${theme.spacing(6)}px`,
    border: "1px solid rgb(187,187,187)",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  marginAuto: { margin: "auto" },
  errorMessage: {
    color: "#a60000"
  },
  containedInput: {
    padding: `0px ${theme.spacing(0.75)}px`,
    height: "1.7em", // Magic value
    fontFamily: FONT_BARON_SANS,
    fontSize: theme.typography.pxToRem(12),
    color: "#000"
  },
  halfContainerWidth: {
    maxWidth: "50%"
  },
  brandImg: {
    marginBottom: theme.spacing(0.5),
    width: "65%"
  },
  brandImgMobile: {
    width: "65%"
  },
  membershipBanner: {
    width: "102%",
    maxWidth: "372px",
    height: "auto"
  },
  threeQuarterHeight: {
    minHeight: "75vh"
  },
  radioIcon: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    borderRadius: theme.typography.pxToRem(12),
    backgroundColor: "white",
    borderStyle: "solid",
    borderWidth: theme.typography.pxToRem(1)
  },
  checkedRadioIcon: {
    "&:before": {
      display: "block",
      position: "absolute",
      width: theme.typography.pxToRem(14),
      height: theme.typography.pxToRem(14),
      top: "50%",
      left: "50%",
      borderRadius: theme.typography.pxToRem(7),
      transform: "translate(-50%, -50%)",
      backgroundColor: "#666666",
      content: " "
    }
  },

  tradeUploadComponentContainer: {
    marginBottom: theme.spacing(1)
  },
  tradeUploadInnerSpacing: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  tradeFileNameMicroAdjustment: {
    paddingTop: theme.spacing(0.5)
  },
  tradeSubgroupRadioInnerSpacing: {
    marginLeft: theme.spacing(0.5)
  },
  tradeCheckbox: {
    width: "30rem"
  },
  tableLayoutFixed: {
    tableLayout: "fixed"
  },
  displayBlock: {
    display: "block"
  },
  modalDivider: {
    float: "right",
    marginLeft: theme.spacing(4.5)
  },
  lineItemDivider: {
    backgroundColor: "#E1E1E1",
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(2)}px 0px`,
    flexBasis: "100%"
  },
  horizontalDivider: {
    backgroundColor: "#E1E1E1",
    margin: `2px 0px ${theme.spacing(1)}px 0px`,
    flexBasis: "100%"
  },
  rightVerticalSeparator: {
    borderRight: "1px solid #ccc"
  },
  leftVerticalWarningSeparator: {
    borderLeft: "5px solid #c66"
  },
  leftVerticalSeparator: {
    borderLeft: "1px solid #ccc"
  },
  leftSpacer: {
    paddingLeft: theme.spacing(1)
  },
  halfLeftSpacer: {
    paddingLeft: theme.spacing(0.5)
  },
  halfRightSpacer: {
    paddingRight: theme.spacing(0.5)
  },
  rightSpacer: {
    paddingRight: theme.spacing(1)
  },
  doubleTopSpacer: {
    paddingTop: theme.spacing(2)
  },
  topSpacer: {
    paddingTop: theme.spacing(1)
  },
  bottomSpacer: {
    paddingBottom: theme.spacing(1)
  },
  doubleBottomSpacer: {
    paddingBottom: theme.spacing(2)
  },
  innerPadding: {
    padding: theme.spacing(0.5)
  },
  doubleInnerPadding: {
    padding: theme.spacing(1)
  },
  tripleInnerPadding: {
    padding: theme.spacing(3)
  },
  doubleLeftPadding: {
    paddingLeft: theme.spacing(2)
  },
  leftPadding: {
    paddingLeft: theme.spacing(1)
  },
  leftMargin: {
    marginLeft: theme.spacing(1)
  },
  doubleLeftMargin: {
    marginLeft: theme.spacing(2)
  },
  tripleLeftMargin: {
    marginLeft: theme.spacing(3)
  },
  rightMargin: {
    marginRight: theme.spacing(1)
  },
  rightPadding: {
    paddingRight: theme.spacing(1)
  },
  doubleRightMargin: {
    marginRight: theme.spacing(2)
  },
  topMargin: {
    marginTop: theme.spacing(1)
  },
  halfTopMargin: {
    marginTop: theme.spacing(0.5)
  },
  doubleTopMargin: {
    marginTop: theme.spacing(2)
  },
  bottomMargin: {
    marginBottom: theme.spacing(1)
  },
  halfBottomMargin: {
    marginBottom: theme.spacing(0.5)
  },
  doubleBottomMargin: {
    marginBottom: theme.spacing(2)
  },
  tripleTopMargin: {
    marginTop: theme.spacing(3)
  },
  tripleRightMargin: {
    marginRight: theme.spacing(3)
  },
  tripleBottomMargin: {
    marginBottom: theme.spacing(3)
  },
  quadruoleBottomMargin: {
    marginBottom: theme.spacing(4)
  },
  quintupleBottomMargin: {
    marginBottom: theme.spacing(5)
  },
  rightAlign: {
    textAlign: "right"
  },
  leftAlign: {
    textAlign: "left"
  },
  checkbox: {
    padding: 0,
    margin: 0,
    border: "none",
    display: "flex",
    justifyContent: "flex-start"
  },
  reduceOpacity: {
    opacity: 0.5
  },
  centeredBlockMenu: {
    display: "inline-block",
    textAlign: "center"
  },
  inputInlinePostalCode: {
    padding: "6px 0",
    width: theme.spacing(6)
  },
  buttonInlinePostalCode: {
    fontSize: 10,
    marginLeft: theme.spacing(1)
  },
  inline: {
    display: "inline"
  },
  flex: {
    display: "flex"
  },
  solidThinGrayBorder: {
    border: "1px solid #bbb"
  },
  buttonFontSize: {
    fontSize: 10
  },
  buttonSmallPadding: {
    padding: theme.typography.pxToRem(14),
    minWidth: theme.typography.pxToRem(250)
  },
  bgButtonFontSize: {
    fontSize: 12
  },
  membershipDialogButtonMarginTop: {
    marginTop: 50
  },
  quantitySelectSize: {
    width: theme.spacing(6.6),
    height: theme.spacing(2.4)
  },
  textField: {
    minWidth: theme.typography.pxToRem(350)
  },
  textFieldHalfSize: {
    minWidth: theme.typography.pxToRem(175)
  },
  textFieldThreeQuarterSize: {
    width: theme.typography.pxToRem(263)
  },
  textFieldOneQuarterSize: {
    width: theme.typography.pxToRem(87)
  },
  checkoutAddressSeparator: {
    marginTop: theme.typography.pxToRem(20)
  },
  infoDialogInnerPadding: {
    padding: theme.spacing(3),
    "&:first-child": {
      padding: theme.spacing(3)
    }
  },
  melissaDialogInnerPadding: {
    padding: `5px 35px 30px 35px`,
    "&:first-child": {
      padding: `5px 35px 30px 35px`
    }
  },
  rhNotificationDialogFixedHeight: {
    height: theme.typography.pxToRem(500)
  },
  fullHeight: {
    height: "100%"
  },
  cardStyle: {
    backgroundColor: "#fafafa",
    padding: theme.typography.pxToRem(40),
    height: "100%"
  },
  ccvBoxImage: {
    height: theme.typography.pxToRem(87),
    width: theme.typography.pxToRem(236),
    padding: theme.spacing(1)
  },
  dialogBoxDivider: {
    width: "100%",
    backgroundColor: "#666",
    margin: 0,
    marginBottom: theme.spacing(2)
  },
  mobileButtonInnerPadding: {
    padding: "16px 22px"
  },
  smallTextField: {
    "& input": {
      padding: "10px"
    }
  },
  pointer: {
    cursor: "pointer"
  },
  modalBigContent: {
    overflow: "auto"
  },
  marginLeftAuto: {
    marginLeft: "auto"
  },
  modalScrollContent: {
    overflowY: "scroll",
    maxHeight: "70vh"
  },
  tag: {
    background: "#DDD",
    "& span": {
      color: MO_PRIMARY_COLOR
    }
  },
  tagInput: {
    borderWidth: theme.typography.pxToRem(1),
    borderStyle: "solid",
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
    gap: theme.typography.pxToRem(4),
    borderColor: BC_PRIMARY_COLOR,
    color: BC_PRIMARY_COLOR,
    fontFamily: FONT_ACUMIN_PRO,
    "& input": {
      width: "100%",
      fontFamily: FONT_ACUMIN_PRO,
      resize: "none",
      "&::placeholder": {
        color: BC_PRIMARY_COLOR,
        fontFamily: FONT_ACUMIN_PRO
      },
      "&:focus": {
        outline: "none"
      },
      minWidth: theme.typography.pxToRem(40),
      border: "none",
      background: "transparent"
    }
  },

  textArea: {
    borderWidth: theme.typography.pxToRem(1),
    borderStyle: "solid",
    borderColor: BC_PRIMARY_COLOR,
    padding: theme.typography.pxToRem(8),
    fontFamily: FONT_ACUMIN_PRO,
    resize: "none",
    "&::placeholder": {
      color: BC_PRIMARY_COLOR,
      fontFamily: FONT_ACUMIN_PRO
    },
    "&:focus": {
      outline: "none"
    },
    border: "none",
    background: "transparent"
  },
  personalizedImage: {
    border: "1px solid #dedede",
    width: "88px",
    height: "88px"
  },
  select: {
    borderColor: "black",
    color: "black",
    backgroundColor: "white"
  },
  personalizedColor: {
    padding: theme.spacing(0.5)
  },
  personalizedSelected: {
    border: "2px solid #666"
  },
  personalizedLineItem: {
    margin: 0,
    paddingBottom: theme.typography.pxToRem(10)
  },
  adyenComponentInput: {
    "& .adyen-checkout__input": {
      border: "1px solid #E9E9E9"
    },
    height: "3.7rem",
    width: "7rem",
    padding: "14px 20px"
  },
  buttonPrimary: {
    color: "white",
    backgroundColor: "black",
    borderColor: "black",
    "&:hover": {
      backgroundColor: "#222",
      borderColor: "#222"
    }
  },
  buttonSecondary: {
    color: "white",
    fontFamily: FONT_BARON_SANS,
    borderColor: MO_SECONDARY_COLOR,
    backgroundColor: MO_SECONDARY_COLOR,
    "&:hover": {
      backgroundColor: "#666",
      borderColor: "#666"
    }
  },
  navbar: {
    minWidth: theme.typography.pxToRem(1200),
    height: theme.typography.pxToRem(120),
    transformOrigin: "top left",
    display: "flex",
    alignItems: "center"
  },
  levelOne: {
    paddingTop: 30,
    paddingBottom: 30
  },
  levelTwo: {
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: "#fafafa"
  },
  lightDivider: {
    backgroundColor: "white",
    marginTop: 0
  },
  defaultDivider: {
    backgroundColor: "#E1E1E1",
    marginTop: 0
  },
  addressDialog: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflow: "auto"
  },
  membersDialog: {
    paddingTop: 30,
    paddingBottom: 50,
    paddingLeft: 50,
    paddingRight: 50,
    overflow: "auto"
  },
  checkoutGiftCardErrorMessage: {
    color: "#a60000"
  },
  myAccountPaymentMethod: {
    backgroundColor: "#e8e8e8",
    color: "black",
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.2)
  },
  myAccountPaymentMethodAction: {
    textTransform: "uppercase",
    border: "1px solid black"
  },
  noMarginTop: {
    marginTop: 0
  },
  noMarginBottom: {
    marginBottom: 0
  },
  removePaymentMethodDialogDivider: {
    width: "100%",
    backgroundColor: "#666",
    margin: 0
  },
  removePaymentMethodDialogPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  widthAuto: {
    width: "auto"
  },
  colorDarkGray: {
    color: "#666"
  },
  membershipPaymentMethod: {
    backgroundColor: "#e8e8e8",
    paddingLeft: theme.spacing(1.2),
    width: "50%",
    marginTop: theme.spacing(0.2),
    marginRight: theme.spacing(0.6),
    marginBottom: theme.spacing(0.6)
  },
  registryContainer: {
    maxWidth: theme.typography.pxToRem(1100),
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.typography.pxToRem(40),
    paddingRight: theme.typography.pxToRem(40),
    display: "flex",
    marginTop: theme.typography.pxToRem(30),
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
    flexDirection: "column"
  },
  imageDesc: {
    color: "white",
    display: "block",
    paddingBottom: theme.typography.pxToRem(14),
    width: theme.typography.pxToRem(455),
    fontFamily: "CaslonRH",
    letterSpacing: theme.typography.pxToRem(-0.3),
    textShadow: "0 1px 2px black"
  },
  imageContent: {
    position: "absolute",
    width: theme.typography.pxToRem(500),
    bottom: 0,
    top: 0,
    display: "flex",
    flexDirection: "column",
    transition: "all 2250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "flex-start",
    paddingTop: theme.typography.pxToRem(40),
    paddingLeft: theme.typography.pxToRem(24),
    boxSizing: "border-box"
  },
  nav: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.typography.pxToRem(12)
  },
  giftRegistrySideBar: {
    width: theme.typography.pxToRem(205),
    boxSizing: "border-box",
    paddingRight: theme.typography.pxToRem(10),
    flex: "none"
  },
  lowerImage: {
    marginTop: theme.typography.pxToRem(60)
  },
  registryBannerSeparator: {
    marginTop: theme.spacing(6)
  },
  registryTopMargin: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(3)
    }
  },
  registryBottomMargin: {
    marginBottom: theme.spacing(4)
  },
  registryHomeLinkButton: {
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    padding: theme.spacing(1.6)
  },
  registryLowerOffset: {
    marginBottom: theme.spacing(4)
  },
  registryUpperOffset: {
    marginTop: theme.spacing(0)
  },
  registryLineItemRemoved: {
    transition: "opacity .3s, filter .3s ease-in-out",
    opacity: "30%",
    filter: "grayscale(100%) blur(1px)"
  },
  positionRelative: {
    position: "relative"
  },
  positionAbsolute: {
    position: "absolute"
  },
  registryLineItemRemovedOverlay: {
    fontSize: theme.typography.pxToRem(16),
    boxShadow: "inset 0px -80px 80px 0px rgba(255,255,255,1)",
    height: "100%",
    zIndex: 1,
    pointerEvents: "none"
  },
  registryLineItemRemovedOverlayMobile: {
    fontSize: theme.typography.pxToRem(16),
    boxShadow: "inset 0px 80px 80px 0px rgba(255,255,255,1)",
    height: "100%",
    zIndex: 1,
    pointerEvents: "none"
  },
  registryItemContainer: {
    margin: `${theme.spacing(1)}px 0px`
  },
  tableHeaderTitle: {
    borderBottom: "1px solid black",
    paddingBottom: 5
  },
  displayNone: {
    display: "none"
  },
  faqAccordion: {
    boxShadow: "none",
    padding: 0,
    /*putting !important because Material-ui do not allow to change the margin
      property on Accordion-content*/
    margin: "0!important",
    "&:before": {
      display: "none"
    }
  },
  checkoutUpdateSessionHeader: {
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    backgroundColor: "#DEDEDE"
  },
  inStockOnSaleDrawer: {
    width: "80%",
    margin: "0 auto",
    left: 0
  },
  collectionGalleryHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  collectionGalleryItemContainer: {
    justifyContent: "center"
  },
  addressFixes: {},
  messageFixes: {
    height: theme.typography.pxToRem(140)
  },
  addressFixesSelected: {
    borderColor: "#000"
  },
  whiteAlert: {
    "&.MuiAlert-outlinedInfo": {
      background: "white",
      borderStyle: "solid",
      fontWeight: "bold",
      borderRadius: 0,
      padding: 0,
      borderColor: "black"
    },
    "& .MuiAlert-message": {
      color: "black"
    }
  },
  grayBackground: {
    background: LIGHT_GRAY
  },
  lighterGrayBackground: {
    background: "#FAFAFA"
  },
  transparentBackground: {
    background: "transparent"
  },
  shoppingCartFooter: {
    background: LIGHT_GRAY,
    paddingTop: theme.typography.pxToRem(60),
    paddingBottom: theme.typography.pxToRem(60)
  },
  checkoutBlockSpacing: {
    marginTop: theme.typography.pxToRem(20),
    marginBottom: theme.typography.pxToRem(20)
  },
  checkoutTextSpacing: {
    marginTop: theme.typography.pxToRem(10),
    marginBottom: theme.typography.pxToRem(10)
  },
  checkoutButton: {
    width: "250px"
  },
  checkoutTopMargin: {
    marginTop: theme.typography.pxToRem(100),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.typography.pxToRem(70)
    },
    [theme.breakpoints.down(375)]: {
      marginTop: theme.typography.pxToRem(40)
    }
  },
  buttonBlockSpacing: {
    marginTop: theme.typography.pxToRem(30),
    marginBottom: theme.typography.pxToRem(43)
  },
  extraButtonBlockSpacing: {
    marginTop: theme.typography.pxToRem(30),
    marginBottom: theme.typography.pxToRem(20)
  },

  lineItemTopMargin: {
    marginTop: theme.typography.pxToRem(20),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.typography.pxToRem(25)
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.typography.pxToRem(30)
    }
  },
  lineItemBottomMargin: {
    marginBottom: theme.typography.pxToRem(20),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.typography.pxToRem(25)
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: theme.typography.pxToRem(30)
    }
  },
  lineItemParagraphTopMargin: {
    marginTop: theme.typography.pxToRem(10),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.typography.pxToRem(15)
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.typography.pxToRem(20)
    }
  },
  lineItemParagraphBottomMargin: {
    marginBottom: theme.typography.pxToRem(10),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.typography.pxToRem(15)
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: theme.typography.pxToRem(20)
    }
  },
  summeryTitle: {
    fontFamily: "Caslon RH",
    fontWeight: FONT_REGULAR,
    fontStyle: "normal",
    marginTop: "6px",
    color: "#000000",
    lineHeight: theme.typography.pxToRem(43),
    fontSize: theme.typography.pxToRem(45),
    [theme.breakpoints.down("md")]: {
      lineHeight: theme.typography.pxToRem(38),
      fontSize: theme.typography.pxToRem(40)
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(24)
    }
  },
  summerySubTitle: {
    fontFamily: FONT_CASLON,
    fontWeight: FONT_REGULAR
  },
  billingSummeryItems: {
    fontFamily: FONT_BARON_SANS,
    fontWeight: 650,
    marginTop: "8px",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: "1px"
  },
  billingSummeryItemsDetails: {
    fontFamily: FONT_BARON_SANS,
    fontWeight: FONT_BOLD,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    marginTop: "8px",
    letterSpacing: "0.2px"
  },
  orderSummerySpacing: {},
  billingSummeryOrderNo: {
    fontFamily: "Caslon RH",
    fontWeight: FONT_REGULAR,
    marginTop: "16px",
    color: "#000000",
    letterSpacing: 0,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(18),
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(20)
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(20)
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(22),
      lineHeight: theme.typography.pxToRem(22)
    }
  },
  summeryIcon: {
    fontSize: theme.typography.pxToRem(10),
    width: "10px !important",
    height: "10px !important",
    marginLeft: theme.spacing(0),
    transition: "all .2s ease-in-out",
    transform: `rotate(${90}deg)`
  },
  rhInputTextContainerWrapper: {
    "& > div:first-child": {
      marginTop: 0
    }
  },
  rhInputTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    marginTop: "17px",

    "& .MuiInputLabel-outlined": {
      transform: "translate(21px, -50%)"
    },
    "& .MuiFormLabel-root.Mui-error": {
      transform: "translate(21px, -160%)"
    },
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        display: "flex",
        width: "100%",
        padding: "14px 20px !important",
        alignItems: "center",
        gap: "10px",
        height: "auto",
        lineHeight: "20px"
      }
    }
  },
  rhInputTextContainerPromocode: {
    "& .MuiInputBase-root .MuiInputBase-input": {
      width: "280px",
      [theme.breakpoints.only("xs")]: {
        width: "210px"
      },
      [theme.breakpoints.only("sm")]: {
        width: "170px"
      }
    },
    "& .MuiInputLabel-root": {
      color: "#000 !important",
      fontSize: "11px !important"
    }
  },
  rhSelectTextContainer: {
    "& .MuiSelect-root": {
      display: "flex",
      width: "100%",
      padding: "14px 20px !important",
      alignItems: "center",
      gap: "10px",
      height: "auto",
      lineHeight: "20px"
    }
  },
  textContainerHeight: {
    height: "24px"
  }
});

const rhClasses = theme => ({
  ...createCommonStyles(theme)
});

const bcStyles = theme => ({
  ...createCommonStyles(theme),
  registryTopMargin: {
    marginTop: theme.spacing(18)
  },
  registryBottomMargin: {
    marginBottom: theme.spacing(8)
  },
  buttonPrimary: {
    backgroundColor: BC_PRIMARY_COLOR,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    borderColor: BC_PRIMARY_COLOR,
    fontFamily: FONT_ACUMIN_PRO,
    color: "white",
    "&:hover": {
      backgroundColor: "#333"
    }
  },
  buttonSecondary: {
    color: "white",
    fontFamily: FONT_ACUMIN_PRO,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    borderColor: BC_SECONDARY_COLOR,
    backgroundColor: BC_SECONDARY_COLOR,
    "&:hover": {
      backgroundColor: "#333",
      borderColor: "#333"
    }
  },
  select: {
    borderColor: BC_SECONDARY_COLOR,
    color: BC_PRIMARY_COLOR,
    backgroundColor: "white"
  },
  textField: {
    minWidth: theme.typography.pxToRem(350),
    "& input": {
      borderColor: BC_PRIMARY_COLOR,
      color: BC_PRIMARY_COLOR,
      fontFamily: FONT_ACUMIN_PRO,
      "&::placeholder": {
        color: BC_PRIMARY_COLOR
      }
    }
  },
  lightDivider: {
    backgroundColor: "white",
    marginTop: 0
  },
  defaultDivider: {
    backgroundColor: BC_SECONDARY_COLOR,
    marginTop: 0
  }
});

const moStyles = theme => ({
  ...createCommonStyles(theme),
  collectionGalleryItemContainer: {
    justifyContent: "start"
  },
  collectionGalleryHeader: {
    marginTop: theme.typography.pxToRem(50),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  buttonPrimary: {
    backgroundColor: MO_PRIMARY_COLOR,
    borderColor: MO_PRIMARY_COLOR,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    fontFamily: FONT_ACUMIN_PRO,
    color: "white",
    "&:hover": {
      backgroundColor: "#666",
      borderColor: "#666"
    }
  },
  buttonSecondary: {
    color: "white",
    fontFamily: FONT_BARON_SANS,
    borderColor: MO_SECONDARY_COLOR,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    backgroundColor: MO_SECONDARY_COLOR,
    "&:hover": {
      backgroundColor: "#666",
      borderColor: "#666"
    }
  },
  navbar: {
    color: "white",
    minWidth: theme.typography.pxToRem(1200),
    transformOrigin: "top left",
    display: "flex",
    alignItems: "center",
    backgroundColor: MO_PRIMARY_COLOR,
    height: theme.typography.pxToRem(160)
  },
  levelOne: {
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: MO_PRIMARY_COLOR
  },
  levelTwo: {
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: MO_PRIMARY_COLOR
  },
  lightDivider: {
    backgroundColor: "white",
    marginTop: 0
  },
  defaultDivider: {
    backgroundColor: "white",
    marginTop: 0
  },
  registryHomeLinkButton: {
    color: "#FFFFFF",
    backgroundColor: "#212121",
    border: "1px solid #212121",
    padding: theme.spacing(1.6),
    "&:hover": {
      backgroundColor: "#484848",
      border: "1px solid #484848"
    }
  },
  registryTopMargin: {
    marginTop: theme.spacing(3)
  }
});

const tnStyles = theme => ({
  ...createCommonStyles(theme),
  buttonPrimary: {
    backgroundColor: BC_PRIMARY_COLOR,
    borderColor: BC_PRIMARY_COLOR,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    fontFamily: FONT_ACUMIN_PRO,
    color: "white",
    "&:hover": {
      backgroundColor: "#333"
    }
  },
  buttonSecondary: {
    color: "white",
    fontFamily: FONT_ACUMIN_PRO,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    borderColor: BC_SECONDARY_COLOR,
    backgroundColor: BC_SECONDARY_COLOR,
    "&:hover": {
      backgroundColor: "#333",
      borderColor: "#333"
    }
  },
  select: {
    borderColor: BC_SECONDARY_COLOR,
    color: BC_PRIMARY_COLOR,
    backgroundColor: "white"
  },
  textField: {
    minWidth: theme.typography.pxToRem(350),
    "& input": {
      borderColor: BC_PRIMARY_COLOR,
      color: BC_PRIMARY_COLOR,
      fontFamily: FONT_ACUMIN_PRO,
      "&::placeholder": {
        color: BC_PRIMARY_COLOR
      }
    }
  },
  lightDivider: {
    backgroundColor: "white",
    marginTop: 0
  },
  modalBigContent: {
    overflow: "auto"
  },
  modalScrollContent: {
    overflowY: "scroll",
    maxHeight: "70vh"
  },
  personalizedImage: {
    border: "1px solid #dedede",
    width: "88px",
    height: "88px"
  },
  personalizedColor: {
    padding: theme.spacing(0.5)
  },
  personalizedSelected: {
    border: "2px solid #666"
  },
  personalizedLineItem: {
    margin: 0,
    paddingBottom: theme.typography.pxToRem(10)
  },
  adyenComponentInput: {
    border: "1px solid #E9E9E9",
    height: "3.7rem",
    width: "7rem",
    padding: "14px 20px"
  },
  membersDialog: {
    paddingTop: 30,
    paddingBottom: 50,
    paddingLeft: 50,
    paddingRight: 50,
    overflow: "auto"
  },
  addressDialog: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflow: "auto"
  }
});

function generateStyles<T extends string, K extends { [key: string]: any }>({
  stylesObj,
  keys,
  ...props
}: {
  stylesObj: (theme: Theme) => K;
  keys: T[];
}) {
  return makeStyles((theme: Theme) => {
    const obj = stylesObj(theme);
    const styles = keys.reduce((acc, next) => {
      return {
        ...acc,
        [next]: obj[next] || {}
      };
    }, {}) as Record<T, K>;
    return createStyles(styles);
  });
}
export const useRHStylesByBrand = (props?: any) => {
  const brand = useRHBrand();
  switch (brand) {
    case "MO":
      return generateStyles({ ...props, stylesObj: moStyles })();
    case "BC":
      return generateStyles({ ...props, stylesObj: bcStyles })();
    case "TN":
      return generateStyles({ ...props, stylesObj: tnStyles })();
    case "SH":
      return generateStyles({ ...props, stylesObj: rhClasses })();
    case "BH":
      return generateStyles({ ...props, stylesObj: rhClasses })();
    default:
      return generateStyles({ ...props, stylesObj: rhClasses })();
  }
};

export default useRHStylesByBrand;
