import { ApolloError, useQuery } from "@apollo/client";
import { querySkuAvailabilityStatusBulk } from "graphql-client-sku-availability/queries/sku-availability-status-bulk";
import { useCountry } from "@RHCommerceDev/hooks/useCountry";
import { useMemo } from "react";

type UseQuantityBulkArgs = {
  postalCode?: Maybe<string>;
  skus: SkuInput[];
  maxQuantity?: Maybe<number> | null;
  defaultQuantity?: number;
  skip?: boolean;
};

type UseQuantityBulkReturn = {
  loading: boolean;
  skuStatus: Maybe<SkuAvailabilityStatus[]>;
  skuAvailabilityStatusBulk: Maybe<SkuAvailabilityStatusBulk>;
  error: ApolloError | undefined;
};

export const useQuantityBulk = ({
  postalCode,
  skus,
  maxQuantity,
  defaultQuantity = 1,
  skip = false
}: UseQuantityBulkArgs): UseQuantityBulkReturn => {
  const country = useCountry();
  const {
    loading,
    data: { skuAvailabilityStatusBulk } = {},
    error
  } = useQuery<
    Pick<Query, "skuAvailabilityStatusBulk">,
    QuerySkuAvailabilityStatusBulkArgs
  >(querySkuAvailabilityStatusBulk, {
    variables: { country, postalCode, skus },
    skip: !skus || !skus?.length || skip
  });

  const skuStatus = useMemo(() => {
    return skuAvailabilityStatusBulk?.skuStatus?.map(s => ({
      ...s,
      maxQuantity: !!(maxQuantity && s?.maxQuantity)
        ? Math.min(s?.maxQuantity, maxQuantity)
        : s?.maxQuantity ?? defaultQuantity
    }));
  }, [defaultQuantity, maxQuantity, skuAvailabilityStatusBulk?.skuStatus]);

  return {
    loading,
    skuStatus,
    skuAvailabilityStatusBulk,
    error
  };
};
