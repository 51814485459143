import { useMemo } from "react";
import { UserContext } from "types";
import { parseUserContextCookie } from "utils/parseUserContextCookie";
import { processEnvServer } from "hooks/useSsrHooks";
import { useIsoCookies } from "hooks/useIsoCookies";

export function useUserContext(deps?: any[]): UserContext {
  const cookie = useIsoCookies(["userContext"])?.userContext;
  return useMemo(() => {
    let userContext: UserContext = parseUserContextCookie(cookie) || {
      country: "",
      userType: "",
      currencyCode: ""
    };
    if (!processEnvServer) {
      const email = "REACT_APP_GUEST_EMAIL";
      const cartId = "REACT_APP_GUEST_CART_ID";
      const userId = "REACT_APP_GUEST_USER_ID";
      const shopperRef = "REACT_APP_GUEST_SHOPPER_REF";

      userContext["email"] =
        (!processEnvServer && localStorage.getItem(email)) || "";
      userContext["cartId"] =
        (!processEnvServer && localStorage.getItem(cartId)) || "";
      userContext["userId"] =
        (!processEnvServer && localStorage.getItem(userId)) || "";
      userContext["shopperRef"] =
        (!processEnvServer && localStorage.getItem(shopperRef)) || "";
    }
    return userContext;
    // TODO: Fix eslint error and remove this comment block
    // React Hook useMemo was passed a dependency list that is not an array literal.
    // This means we can't statically verify whether you've passed the correct dependencies react-hooks/exhaustive-deps
    // React Hook useMemo has missing dependencies: 'REACT_APP_GUEST_CART_ID' and 'REACT_APP_GUEST_EMAIL'.
    // Either include them or remove the dependency array react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps?.concat([cookie]) || [cookie]);
}

export default useUserContext;
